class WebGLUtils {
  // ...
  constructor() {
    this.canvas = document.createElement("canvas");

    this.gl =
      this.canvas.getContext("webgl") ||
      this.canvas.getContext("experimental-webgl");
  }
  getInformation() {
    if (!this.gl) return null;

    const debugInfo = this.gl.getExtension("WEBGL_debug_renderer_info");
    const renderer = this.gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    const vendor = this.gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);

    return {
      renderer,
      vendor,
      maxTextureSize: this.gl.getParameter(this.gl.MAX_TEXTURE_SIZE),
      maxCombinedTextureImageUnits: this.gl.getParameter(
        this.gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS
      ),
      maxVertexAttribs: this.gl.getParameter(this.gl.MAX_VERTEX_ATTRIBS),
      maxVertexTextureImageUnits: this.gl.getParameter(
        this.gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS
      ),
      maxFragmentUniformVectors: this.gl.getParameter(
        this.gl.MAX_FRAGMENT_UNIFORM_VECTORS
      ),
    };
  }

  canSupportReactGlobeGL(webglInfo) {
    if (!webglInfo) {
      console.log("WebGL is not supported.");
      return false;
    }

    // Check if WebGL2 is supported (optional but recommended for better performance)
    const canvas = document.createElement("canvas");
    const isWebGL2Supported = !!canvas.getContext("webgl2");

    // Define minimum requirements for React Globe GL
    const MIN_TEXTURE_SIZE = 4096;
    const MIN_VERTEX_ATTRIBS = 16;

    // Check WebGL capabilities
    const isTextureSizeSufficient =
      webglInfo.maxTextureSize >= MIN_TEXTURE_SIZE;
    const isVertexAttribsSufficient =
      webglInfo.maxVertexAttribs >= MIN_VERTEX_ATTRIBS;

    // Example log output to see details
    console.log(`GPU Vendor: ${webglInfo.vendor}`);
    console.log(`GPU Renderer: ${webglInfo.renderer}`);
    console.log(`Max Texture Size: ${webglInfo.maxTextureSize}`);
    console.log(`Max Vertex Attributes: ${webglInfo.maxVertexAttribs}`);
    console.log(`WebGL2 Supported: ${isWebGL2Supported}`);

    // Determine support for React Globe GL
    if (isTextureSizeSufficient && isVertexAttribsSufficient) {
      console.log("This browser can support React Globe GL.");
      return true;
    } else {
      console.log("This browser may not fully support React Globe GL.");
      return false;
    }
  }
}

module.exports = WebGLUtils;
