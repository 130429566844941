//create a container component that will hold the other components
import React, { useEffect } from 'react';
//load LiveViewContainerHelper
import LiveViewContainerHelper from '../../helpers/LiveViewContainerHelper';
function Container() {
    //set message state
    useEffect(() => { 
        document.body.style.margin = '0';
        document.body.style.overflow = "scroll";
        const liveviewHelper = new LiveViewContainerHelper({
            key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjaGFudHMiOlt7Im1lcmNoYW50SWQiOiI5MSIsImNvdW50cnlDb2RlIjoiR0IifV0sImlhdCI6MTcyNDMxMjM2MCwiZXhwIjoxNzU1ODQ4MzYwfQ.vYg8JxVjGavfKJpgJTGenoa-98k_HY_F36amzzaUZWI',
            keytype : 1, //JWT
            iframeContainerName: 'iframeContainer',
            endpoint: process.env.REACT_APP_SITE_DOMAIN
          });                  
      }, []);

 
    return (
        <div>
        <h1>Parent Container Page</h1>
        <div id="iframeContainer">

        </div>

      </div>
    );
}

export default Container;