//create a javascript class for helping methods

class LiveViewContainerHelper {
  //create constructor that accepts the key and the iframe name
  constructor(settings) {


  }

  init(settings) {
    this.key = settings.key;
    this.iframeContainerName = settings.iframeContainerName;
    this.keyType = settings.keytype; //0 for AES, 1 for JWT
    this.endpoint = settings.endpoint;

    this.createIframe();
    this.listenToIframeMessage();
  }

  createIframe = () => {
    // Create the iframe element
    const iframe = document.createElement("iframe");
    iframe.id = "live_merchant_view";
    iframe.style.width = "100%";
    iframe.style.height = "500px";
    iframe.style.border = "none";

    //check if the endpoint is set and that it ends with a slash
    if (!this.endpoint.endsWith("/")) {
      this.endpoint = this.endpoint + "/";
    }

    iframe.src = `${this.endpoint}liveview`;
    // Append the iframe to the container element , clear the container first
    document.getElementById(this.iframeContainerName).innerHTML = "";
    document.getElementById(this.iframeContainerName).appendChild(iframe);
    this.iframe = iframe;
  };
  // Function to send encrypted data to the iframe
  sendMessageToIframe = () => {
    // Example encrypted data (replace with your actual encryption logic)
    const encryptedData = this.key;
    const msg = {
      type: "ENCRYPTED_DATA",
      key: encryptedData,
      keytype: this.keyType,
    };
    // Post the message to the iframe

    this.iframe.contentWindow.postMessage(msg, window.location.origin);
  };

  listenToIframeMessage = () => {
    // Listen for the iframe to finish loading, wait for incoming post message from the iframe
    window.addEventListener("message", this.handleIncomingMessage);
  };

  handleIncomingMessage = (event) => {
    if (event.origin !== window.location.origin) {
      // Ensure the message is from a trusted source
      return;
    }

    if (event.data.type !== "LIVE_VIEW_MESSAGE") {
      // Ignore messages that are not of the expected type
      return;
    }

    const message = event.data.message;
    switch (message) {
      case "READY":
        this.sendMessageToIframe();
        break;
      case "RESIZE":
        //console.log("Resize event received" + JSON.stringify(event.data));
        this.handleResize(event.data, this);
        break;
      default:
        break;
    }
    if (message === "READY") {
      // The iframe is ready to receive messages
      this.sendMessageToIframe();
    }
  };
  handleResize = (data, self) => {
    //post message to container
    self.iframe.width = data.dims.width + "px";
    self.iframe.style.height = data.dims.height + "px";
  };
}

module.exports = LiveViewContainerHelper;
