import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const NowOnCheckout = () => (
  <Fragment>
    <Typography color="inherit">Now on checkout</Typography>
    The number of customers currently on the Checkout page. 
  </Fragment>
);

export default NowOnCheckout;
