import { useEffect, useState } from "react";
import { state, LiveViewHelper } from "../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import styles from "../css/liveview.module.css";
const LastOrder = observer(() => {
  //latestOrderInfo is an object with the following properties: countryName, amount,city
  const [latestOrderInfo, setLatestOrderInfo] = useState({});
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    if (!state.merchantData || !state.merchantData.data.daily.latestOrder) return;
    setLatestOrderInfo(state.merchantData.data.daily.latestOrder);
    setAmount(
      state.formatAmount(state.merchantData.data.daily.latestOrder.amount)
    );
  }, [state.merchantData, state.currenctCurrencyInfo]);

  return (
    <div className={styles.last_order_container}>
   
    A shopper from {latestOrderInfo?.city},{" "}
      {latestOrderInfo?.countryName} just made a purchase totaling {amount}
    </div>
  );
});

export default LastOrder;
