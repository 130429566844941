import { observer } from "mobx-react-lite";
import styles from "../css/liveview.module.css";
import { state } from "../helpers/LiveViewHelper";
import DataBox from "./common/dataBox";
import Loader from "./common/loader";
import { useEffect, useRef, useState } from "react";
import LastOrder from "./LastOrder";
import Globe from "react-globe.gl";
import * as THREE from "three";
import * as topojson from "topojson-client";
import GlobeControls from "./GlobeControls";
import GlobeManager from "../helpers/globeManager";
import WebGLUtils from "../helpers/webGLutils";
import { set } from "mobx";
const GlobeSphere = observer(() => {
  const [globeWidth, setGlobeWidth] = useState(0);
  const [globeHeight, setGlobeHeight] = useState(0);
  const [dataPoints, setDataPoints] = useState([]);
  const [htmlDataPoints, setHtmlDataPoints] = useState([]);
  const globeEl = useRef();
  const [globeProps, setGlobeProps] = useState({});
  const [globeKey, setGlobeKey] = useState(0);
  const [globeComponentReady, setGlobeComponentReady] = useState(false);
  const [maxCoutryAmount, setMaxCountryAmount] = useState(0);
  const [countries, setCountries] = useState({ features: [] });
  //const [allowInteration, setAllowInteraction] = useState(false);
  const [hoverD, setHoverD] = useState(null);
  const [noData , setNoData] = useState(false);
  const animationStartTime = useRef(Date.now());
  const timeoutRef = useRef(null); // Ref to keep track of the timeout
  const version = "B";

  const globeManager = useRef(new GlobeManager()).current;

  const [htmlMarker, setHtmlMarker] = useState(document.createElement("div"));
  const htmlArr = useRef([]);
  //create ref to the globe container
  const globeContainer = useRef();

  const polygonsMaterial = new THREE.MeshBasicMaterial({
    color: "#DEDEDE", //#DEDEDE
    transparent: false,
  });
  // Define material for the globe surface to make it white
  const globeMaterial = new THREE.MeshBasicMaterial({
    color: "#F6F6F6",
    transparent: false, // Ensure no transparency
  });
  const [landPolygons, setLandPolygons] = useState([]);

  useEffect(() => {
    if (version === "B") {
      fetch("countries_pol.geojson")
        .then((res) => res.json())
        .then(setCountries);
    }
    // load data
    state.setIsGlobeReady(false);
    //get container width, container id is globeContainer
    const globeContainer = document.getElementById("globeContainer");
    const width = globeContainer.clientWidth - 150;
    const height = globeContainer.clientHeight;

    //register before mouse down

    //set globe width and height
    //listen to on resize
    window.addEventListener("resize", () => {
      const width = globeContainer.clientWidth - 150;
      //const height = globeContainer.clientHeight;
      setGlobeWidth(width);
      setGlobeHeight(height);
    });
    setGlobeWidth(width);
    setGlobeHeight(height);
    //removeHtmlElements();
    fetch("//unpkg.com/world-atlas/land-110m.json")
      .then((res) => res.json())
      .then((landTopo) => {
        setLandPolygons(
          topojson.feature(landTopo, landTopo.objects.land).features
        );
      });

    //setGlobeKey((prevKey) => prevKey + 1);

    document.addEventListener("visibilitychange", handleVisibilityChange);
    state.onCurrencyChange(() => {
      currencyChanged();
      handleLatestOrders();
    });
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      globeManager.update({ temporaryGlobeStop: true });
    } else {
      globeManager.update({ temporaryGlobeStop: false });
    }
  };

  const onGlobeComponentReady = () => {
    globeEl.current.pointOfView({ lat: 0, lng: 0, altitude: 1.4 });
    setGlobeComponentReady(true);
    // setTimeout(() => {

    // }, 2000);
  };

  useEffect(() => {}, [state.isGlobeReady]);
  useEffect(() => {
      handleLatestOrders();
  }, [state?.merchantData?.data?.daily?.latestOrders]);

  const handleLatestOrders = () => {
    if (!state.merchantData) return;    
    const latestOrders = state.getLatestOrdersData();
 
    if (latestOrders.length === 0) {  
      setNoData(true);
      return;
    }
    setNoData(false);
    const points = [];

    for (var i = 0; i < latestOrders.length; i++) {
      //generate random lat and long
      //random amount between 100 and 1000
      let lOrder = latestOrders[i];

      //const amount = Math.random() * 1000 + 100;
      points.push({
        amount: state.formatAmount(lOrder.amount, "currency"),
        size: lOrder.amount / 100,
        city: lOrder.city,
        countryName: lOrder.countryName,
        coordinates: {
          lat: lOrder.coordinates.lat,
          lng: lOrder.coordinates.lng,
        },
        index: i,
      });
    }
    if (dataPoints.length === 0) {
    }
    setDataPoints([...points]);

    //set max amount in countries
    const countries = state.merchantData.data.daily.countries;
    let maxAmount = 0;
    for (let key in countries) {
      const country = countries[key];
      if (country.amount > maxAmount) {
        maxAmount = country.amount;
      }
    }
    setMaxCountryAmount(maxAmount);

  }
  // Example data points
  const points = [
    { lat: 51.5, lng: -0.1, size: 0.1 },
    // ... other points
  ];
  // const removeHtmlElements = () => {
  //   //remove all elements with class globeHtmlElement
  //   const elements = document.getElementsByClassName("globeHtmlElement");
  //   while (elements[0]) {
  //     elements[0].parentNode.removeChild(elements[0]);
  //     console.log("removing element");
  //   }

  //   setTimeout(() => {
  //     removeHtmlElements();
  //   }, 200);
  // };
  const currencyChanged = () => {
    setGlobeKey((prevKey) => prevKey + 1);
    globeManager.reset();
    setTimeout(() => {
      globeManager.rotateToNextCoordinate(setHtmlDataPoints);
      globeManager.moveToCoordinate(0, 0, 2000);
    }, 5000);
  };

  useEffect(() => {
    if (globeEl.current) {
      // Add a wheel event listener to allow scrolling
      const globeContainer = document.getElementById("globeContainer");
      //globeEl.current.controls().autoRotate = state.globeConfig.rotate;
      //globeEl.current.controls().autoRotateSpeed = 0.7;

      globeEl.current.pointOfView({ lat: 0, lng: 0, altitude: 2.4 });
      //globeEl.current.controls().enableZoom = false;

      const mouseEnter = (e) => {
        //reset globe point of view immidiately
        //setTempGlobeStop(true);
        globeManager.update({ temporaryGlobeStop: true });

        //setAllowInteraction(false);
        //create settimeout for 3 seconds now - animationStartTime
        const currentTime = Date.now();
        const timeDiff = currentTime - animationStartTime.current;
        //time diff in milliseconds
        // setTimeout(() => {
        //   //setTempGlobeStop(false);
        //   setAllowInteraction(true);
        // }, 3000 - timeDiff);
      };

      const mouseLeave = (e) => {
        //console.log("mouse leave");
        //reset globe point of view immidiately
        //setTempGlobeStop(false);
        globeManager.update({ temporaryGlobeStop: false });
      };

      globeContainer.addEventListener("mouseenter", mouseEnter);
      globeContainer.addEventListener("mouseleave", mouseLeave);

      globeManager.update({
        globeEl: globeEl,
      });
    }
  }, [globeEl]);

  useEffect(() => {
    globeManager.update({ dataPoints });
  }, [dataPoints]);

  useEffect(() => {
    globeManager.update({ rotate: state.globeConfig.rotate });

    let gpuSupported = state.globeConfig.isGpuSupported || false;

    if (!state.globeConfig.gpuTestPerformed) {
      const glUtils = new WebGLUtils();
      const perfData = glUtils.getInformation();
      gpuSupported = glUtils.canSupportReactGlobeGL(perfData);
      state.setGpuTestPerformed(gpuSupported);
    }

    if (gpuSupported && state.isGlobeReady) {
  
      startOrbitter();
    }
    //globeManager.rotate = state.globeConfig.rotate;
    //console.log("globe rotate", state.globeConfig.rotate);
  }, [state.globeConfig.rotate, state.isGlobeReady]);

  // useEffect(() => {
  //   const globeContainer = document.getElementById("globeContainer");
  //   globeContainer.style.cursor = allowInteration ? "default" : "progress";
  // }, [allowInteration]);

  const startOrbitter = () => {
    animationStartTime.current = Date.now();

    setTimeout(() => {
      globeManager.rotateToNextCoordinate(setHtmlDataPoints);
    }, 0);
    //rotateToNextCoordinate();

    return () => clearTimeout(timeoutRef.current);
  };

  const getGreyScaleColorByAmount = (amount) => {
    // Define the lightest (white) and darkest (near-black) grayscale values
    const lightestColor = 215; // white
    const darkestColor = 100; // a dark grey to avoid total black

    // Ensure amount is within 0 to maxAmount
    amount = Math.max(0, Math.min(amount, maxCoutryAmount));

    // Calculate the grayscale value
    const greyScaleValue =
      lightestColor -
      (amount / maxCoutryAmount) * (lightestColor - darkestColor);

    // Convert to integer
    const greyValueInt = Math.round(greyScaleValue);

    // Return the color in rgb format
    return `rgb(${greyValueInt}, ${greyValueInt}, ${greyValueInt})`;
  };

  const getCountryInfo = (code) => {
    const countries = state?.merchantData?.data?.daily?.countries;
    if (countries && countries[code]) {
      return {
        amount: countries[code].amount,
        transactions: countries[code].transactions,
      };
    }
    return { amount: 0, transactions: 0 };
  };
  useEffect(() => {
    if (dataPoints.length === 0 || landPolygons.length === 0) return;
 
    const baseProps = {
      globeMaterial: globeMaterial,
      backgroundColor: "rgba(0,0,0,0)", // Transparent background
      polygonsData: landPolygons,
      pointsData: dataPoints,
      pointLat: (d) => d.coordinates.lat,
      pointLng: (d) => d.coordinates.lng,
      pointColor: () => "#F15A2B",
      pointRadius: 0.4, // Adjust size of the circles
      pointAltitude: 0.02,
      pointsTransitionDuration: 0,
      htmlElementsData: htmlDataPoints,

      //polygonStrokeColor:() => '#111',
      htmlElementAltitude: 1,
      htmlLat: (d) => d.coordinates.lat,
      htmlLng: (d) => d.coordinates.lng,
      htmlTransitionDuration: 0,
    };
    if (version === "B" && countries.features.length > 0) {
      //console.log("countries", countries.features.length);
      baseProps.lineHoverPrecision = 0;
      baseProps.polygonsData = countries.features.filter(
        (d) => d.properties.ISO_A2 !== "AQ"
      );
      baseProps.polygonSideColor = () => "rgba(0, 0, 0, 0)";
      baseProps.polygonStrokeColor = () => "rgba(0, 0, 0, 0.09)";
      baseProps.polygonLabel = ({ properties: d }) => {
        let countryInfo = getCountryInfo(d.ISO_A2);
        return `<div class='polLabel'><b class='pltitle'>${d.ADMIN} (${
          d.ISO_A2
        }):</b> <br />
         <span class='pltitle'> Amount:</span> <i class='plamount'>${state.formatAmount(
           countryInfo.amount
         )}</i><br/>
         <span class='pltitle'>Transactions:</span> <i class='plamount'>${state.formatAmount(
           countryInfo.transactions,
           "number"
         )}</i></div>`;
      };

      baseProps.onPolygonHover = (d) => {
        setHoverD(d);
      };
      baseProps.polygonsTransitionDuration = 300;

      //baseProps.polygonCapMaterial = null;
    } else {
      baseProps.polygonCapMaterial = polygonsMaterial;
      baseProps.polygonSideColor = () => "rgba(0, 0, 0, 0)";
    }

    setGlobeProps(baseProps);
    if (globeComponentReady) {
      setTimeout(() => {
        //wait a little for polligons to load
        //console.log("globe ready");
        state.setIsGlobeReady(true);
      }, 2000);
    }
  }, [
    dataPoints,
    htmlDataPoints,
    countries,
    globeComponentReady,
    landPolygons,
  ]);

  useEffect(() => {
    // if (htmlMarker!==null && htmlMarker !== undefined && htmlMarker !== "undefined" && htmlMarker !== "") {
    //   htmlMarker.innerHTML = "test";
    // }
    //console.log("html points", htmlDataPoints.length);
  }, [htmlDataPoints]);
  return (
    <div ref={globeContainer} className={styles.live_view_globe_container}>
      <div className={styles.globe_no_data} data-show={noData}>
          NO DATA
      </div>
      <LastOrder />
      <GlobeControls />
      <Loader isLoading={!state.isGlobeReady && !noData} message="loading globe..." />
      {1==1?<div
        className={styles.inner_globe_container}
        style={{
          margin: "30px",
          opacity: state.isGlobeReady ? 1 : 0,
          transition: "opacity 1s",
        }}
      >
        <Globe
          key={globeKey}
          width={globeWidth}
          globeImageUrl={null}
          height={globeHeight}
          ref={globeEl}
          {...globeProps}
          onGlobeReady={onGlobeComponentReady}
          showGlobe={true}
          showAtmosphere={false}
          cameraPosition={[10, 0, 100]}
          polygonAltitude={(d) => {
            if (hoverD !== null) {
              return d === hoverD ? 0.06 : 0.005;
            }
            return 0.005;
          }}
          polygonCapColor={(d) => {
            if (d === hoverD) {
              return "#F15A2B";
            }
            let amount = getCountryInfo(d.properties.ISO_A2).amount;
            let color = getGreyScaleColorByAmount(amount);
            return color;
          }}
          htmlElement={(d) => {
            //const x = document.createElement("div");
            //setHtmlMarker(x);
            const el = htmlMarker;
            if (el) {
              el.style.color = "white";
              //el.className = "globeHtmlElement";
              //el.style.width = `${d.size}px`;
              el.style.backgroundColor = "#707070"; // Set background color
              el.style.padding = "5px"; // Add padding for rectangle effect
              el.style.borderRadius = "5px"; // Optional: rounded corners
              el.style.pointerEvents = "auto";
              el.style.fontSize = "12px";
              el.style.cursor = "pointer";
              el.style.marginTop = "-15px";
              el.style.marginLeft = "-15px";
              el.innerHTML = `${d.amount}`; // ${d.countryName} ${d.city}`; // Show the amount

              el.onclick = () => console.log(d);
              return el;
            }
          }}
        />
      </div>:null}
    </div>
  );
});

export default GlobeSphere;
