import React from "react";
import { Line } from "react-chartjs-2";
import { state } from "../../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import moment from "moment";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { format } from "crypto-js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const TransactionsGraph = observer(({ data, format, height = 70 }) => {
  const formatDate = (date) => {
    //set the date format to 25 Aug 2021
    return moment(date).format("DD MMM YYYY");
  };
 
  const chartData = {
    labels: data.hours.map((hour) => hour + ":00"), // Format hours to show as "01:00", "02:00", etc.
    datasets: [
      {
        label: formatDate(Object.keys(data.amounts)[1]),
        data: Array(24).fill(null).map((_, hour) => {
          // Find the data entry for the current hour
     
          const entry = data.amounts[Object.keys(data.amounts)[1]]?.find(x => x.hour === hour);
          // Return total if it exists, otherwise null
          //return entry && entry.exists ? entry.total : null;
          return  entry && entry.total;
        }),
        fill: false,
        borderColor: "#FC8E6C",
        tension: 0.4,
        borderDash: [4, 4],
        pointRadius: 0,
      },
      {
        label: formatDate(Object.keys(data.amounts)[0]),
        data: Array(24).fill(null).map((_, hour) => {
          // Find the data entry for the current hour
          const entry = data.amounts[Object.keys(data.amounts)[0]]?.find(x => x.hour === hour);
          // Return total if it exists, otherwise null
          return entry && entry.exists ? entry.total : null;
        }),
        fill: false,
        borderColor: "#F15A2B",
        tension: 0.4,
        borderDash: [],
        pointRadius: function (context) {
          const index = context.dataIndex;
          const length = context.dataset.data.length;
          // Show point only at the start and end of the line
          return index === 0 || index === length - 1 ? 3 : 0;
        },
        pointStyle: "circle", // Define the style of the point if shown
      },
    ],
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(255, 255, 255, 1)';
      tooltipEl.style.borderRadius = '5px';
      tooltipEl.style.border = '1px solid #999';
      tooltipEl.style.color = 'black';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.fontSize = '12px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      //set min width to 100px
      tooltipEl.style.minWidth = '200px';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
 
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);
  
      const tableHead = document.createElement('thead');
  
      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;
  
        const th = document.createElement('th');
        th.style.borderWidth = 0;
        //align text to the left
        th.style.textAlign = 'left';
        const text = document.createTextNode(title);
  
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        const isDashed = context.chart.data.datasets[i].borderDash.length>0;
        const span = document.createElement('span');
        //span.style.background = "red";// colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        //set dash border for the span
        span.style.borderStyle = isDashed?'dashed':'solid';
        span.style.marginRight = '10px';
        //span.style.height = '1px';
        span.style.width = '30px';
        span.style.display = 'inline-block';
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;
  
        const td = document.createElement('td');
        td.style.borderWidth = 0;
  
        const text = document.createTextNode(body);
  
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom", // Change 'top' to 'bottom'
        labels: {
          usePointStyle: false, // Use point style instead of the default box
          pointStyle: "line", // Set point style to 'line'
          generateLabels: (chart) => {
            const { datasets } = chart.data;
            return datasets.map((dataset, i) => ({
              text: dataset.label,
              fillStyle: dataset.borderColor,
              hidden: !chart.isDatasetVisible(i),
              lineCap: dataset.borderCapStyle,
              lineDash: dataset.borderDash.length !== 0 ? [2, 2] : [],
              lineDashOffset: dataset.borderDashOffset,
              lineJoin: dataset.borderJoinStyle,
              strokeStyle: dataset.borderColor,
              pointStyle: "line",
            })); // Return an empty object for each dataset
          },
        },
      },
      tooltip: {
        enabled:false,
        external: externalTooltipHandler,
        mode: "index",
        intersect: false,
        usePointStyle: true,
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the tooltip title text color to black
        bodyColor: "black", // Set the tooltip body text color to black
        borderColor: "rgba(0, 0, 0, 0.1)", // Optional: Add a border color for tooltip
        borderWidth: 1, // Optional: Set the border width
        shadowOffsetX: 2, // Add box shadow offset in the x-direction
        shadowOffsetY: 2, // Add box shadow offset in the y-direction
        shadowBlur: 10, // Set the blur radius for the shadow
        shadowColor: "rgba(0, 0, 0, 0.1)", // Set the shadow color and opacity
        callbacks: {
          labelPointStyle: function (tooltipItem,data) {
          
            return {              
              pointStyle: "line", // Set the point style to line
              rotation: 0, // No rotation needed for lines 
            };
          },
          labelColor: function(context) {
           // debugger;
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: 'transparent',
              borderWidth: 7,
              borderDash: [4, 4],
            };
          },
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              if (format.type == "currency") {
                label += state.formatAmount(context.parsed.y);

                // label += new Intl.NumberFormat("en-US", {
                //   style: "currency",
                //   currency: format.currency,
                //   currencyDisplay: "narrowSymbol",
                // }).format(context.parsed.y);
              } else {
                label += context.parsed.y;
              }
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Time of Day",
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
        },
        grid: {
          display: true, // Show horizontal grid lines
          drawBorder: false, // Optionally hide the border line on the Y axis
        },
        border: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return state.formatAmount(value, format.type); // Format the Y-axis labels
            if (format.type == "currency") {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: format.currency,
                currencyDisplay: "narrowSymbol",
              }).format(value);
            }
            return value.toLocaleString(); // Format the Y-axis labels
          },
          stepSize: function (context) {
            if (Object.keys(data.amounts).length == 0) return 0;
            const maxDataValue = Math.max(
              ...data.amounts[Object.keys(data.amounts)[1]],
              ...data.amounts[Object.keys(data.amounts)[0]]
            );
            const stepSize = Math.ceil(maxDataValue / 2 / 10000) * 10000; // Adjust step size to show three values
            return stepSize;
          },
          max: function (context) {
            if (Object.keys(data.amounts).length == 0) return 0;
            const maxDataValue = Math.max(
              ...data.amounts[Object.keys(data.amounts)[1]],
              ...data.amounts[Object.keys(data.amounts)[0]]
            );
            const max = Math.ceil(maxDataValue / 20000) * 20000; // Adjust max value to the next multiple of 20,000
            return max;
          },
          beginAtZero: true,
        },
      },
    },
  };

  return <Line data={chartData} options={options} height={height} />;
});

export default TransactionsGraph;
