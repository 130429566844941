import { state } from "../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import LVSwitch from "./common/switch";
import style from "../css/liveview.module.css";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
const LangSwitch = observer(() => {
  const [usdCurrencyDisplay, setUsdCurrencyDisplay] = useState("USD");
  const [merchantCurrencyDisplay, setMerchantCurrencyDisplay] = useState("XXX");
  useEffect(() => {
    if (!state.merchaantCurrencyInfo) return;

    setMerchantCurrencyDisplay(state.merchaantCurrencyInfo.code);
    setUsdCurrencyDisplay(state.usdCurrencyInfo.code);

 
  }, [state.merchaantCurrencyInfo,state.currencyInfo]);

  const handleLangChange = (lang) => {
    //state.setIsGlobeReady(false);
    state.toggleCurrency();
    state.setCurrencyChanged();
  };

  // useEffect(() => {
  //     alert(state.currencyInfo.displayCurrencyCode);
  // },[state.currencyInfo.displayCurrencyCode]);
  return (
    <div className={style.currencySwitchContainer}
      style={{ display: "inline-flex", flexFlow: "wrap", marginLeft: "30px" }}
      data-show={merchantCurrencyDisplay != "USD"}
    >
      <div onClick={()=> handleLangChange()} data-selected={state.currenctCurrencyInfo.code == merchantCurrencyDisplay} className={style.left_cur}>{merchantCurrencyDisplay}</div>
      {/* <LVSwitch handleLangChange={handleLangChange} /> */}
      <div onClick={()=> handleLangChange()} data-selected={state.currenctCurrencyInfo.code == "USD"} className={style.right_cur}>{usdCurrencyDisplay}</div>
    </div>
  );
});

export default LangSwitch;
