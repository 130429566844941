import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const DashboardTime = () => (
  <Fragment>
    <Typography color="inherit">Dashboard Time</Typography>
    {
      "The displayed data is from midnight, in your time zone."
    }
  </Fragment>
);

export default DashboardTime;
