import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const TrxAmount = () => (
  <Fragment>
    <Typography color="inherit">Transactions Amount</Typography>

    {
      "The customers' total purchases in checkout represent the actual sales transactions, from midnight, in your time zone."
    }
  </Fragment>
);

export default TrxAmount;
