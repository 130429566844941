import { useEffect, useState } from "react";
import styles from "../../css/liveview.module.css";
const LineGraphLegend = ({ graphData }) => {
    const [today, setToday] = useState();
    const [yesterday, setYesterday] = useState(); 
    useEffect(() => {
     
        if (!graphData) return;

        const dates = Object.keys(graphData.amounts);
        setToday(dates[0]);
        setYesterday(dates[1]);
 
  
    }, [graphData]);

    return (
        <div className={styles.line_chart_legend}>
            <div className={styles.legend_dashed}></div>
            <div>{yesterday}</div>
            <div className={styles.legend_line}></div>
            <div>{today}</div>
        </div>
    )
};

export default LineGraphLegend;