import styles from "../css/liveview.module.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { state } from "../helpers/LiveViewHelper";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import SvgIcon from "./common/SvgIcon";
const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#F15A2B",
  "&:hover": {
    backgroundColor: orange[700],
  },
}));

const BTN_TEXT = {
  START: "Rotate",
  STOP: "Stop Rotation",
};
const GlobeControls = observer(() => {
  const [show, setShow] = useState(false);
  const [rotateButtonTxt, setRotateButtonTxt] = useState(
    state.globeConfig.rotate ? BTN_TEXT.STOP : BTN_TEXT.START
  );
  useEffect(() => {
    if (state.globeConfig) {
      if (state.globeConfig.isGpuSupported) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [state.globeConfig]);

  const onClick = () => {
    state.toggleGlobeRotation();
    setRotateButtonTxt(
      state.globeConfig.rotate ? BTN_TEXT.STOP : BTN_TEXT.START
    );
  };
  return (
    <div
      className={styles.globeControler_container}
      data-show={show}
      onClick={onClick}
    >
      {state.globeConfig.rotate ? (
        <SvgIcon
          paths={[
            "M4.45007 9.78011C4.86443 9.04072 5.9204 8.93695 6.53526 9.53365C6.94963 9.93578 7.04319 10.5455 6.74913 11.0254C6.48179 11.5183 6.26793 12.0242 6.10753 12.5431C5.94713 13.0879 5.42583 13.4511 4.85107 13.4511H4.8377C3.96887 13.4511 3.30054 12.6469 3.5545 11.8297C3.78174 11.1162 4.0758 10.4417 4.45007 9.78011Z",
            "M4.8377 16.0455H4.86443C5.4392 16.0455 5.9605 16.4087 6.1209 16.9795C6.2813 17.4984 6.49516 18.0043 6.76249 18.4842C7.04319 18.9771 6.93626 19.5739 6.53526 19.976C5.9204 20.5727 4.85107 20.4559 4.4367 19.7165C4.06244 19.055 3.76837 18.3675 3.5545 17.667C3.30054 16.8498 3.96887 16.0455 4.8377 16.0455Z",
            "M10.7992 24.7107C10.064 24.5032 9.35562 24.2178 8.67392 23.8546C7.91202 23.4524 7.79172 22.4277 8.39322 21.8439L8.43332 21.805C8.83432 21.4029 9.47592 21.3121 9.98385 21.5845C10.4784 21.8439 10.9863 22.0515 11.521 22.2071C12.0824 22.3628 12.4567 22.8687 12.4567 23.4265V23.4654C12.4567 24.2956 11.6279 24.9442 10.7992 24.7107Z",
            "M15.1434 3.60552V4.46166C20.4232 5.09728 24.5 9.45582 24.5 14.7483C24.5 19.4701 21.2519 23.4524 16.7875 24.6977C15.9587 24.9312 15.1434 24.2956 15.1434 23.4524V23.4265C15.1434 22.8687 15.5043 22.3628 16.0523 22.2071C19.394 21.2602 21.8267 18.2896 21.8267 14.7483C21.8267 10.8957 18.9395 7.70462 15.1434 7.08197V9.06666C15.1434 10.2212 13.7131 10.8049 12.871 10.0006L10.0239 7.30249C9.48928 6.79659 9.48928 5.96639 10.0239 5.44752L12.8577 2.68452C13.6998 1.86729 15.1434 2.45103 15.1434 3.60552Z",
            "M10.9501 12.2474C11.4057 11.7977 12.1444 11.7977 12.6 12.2474L14.0248 13.6538L15.4495 12.2475C15.9051 11.7978 16.6438 11.7978 17.0994 12.2475C17.555 12.6973 17.555 13.4264 17.0994 13.8761L15.6747 15.2824L17.0994 16.6886C17.555 17.1383 17.555 17.8674 17.0994 18.3172C16.6438 18.7669 15.9051 18.7669 15.4495 18.3172L14.0248 16.9109L12.6 18.3173C12.1444 18.767 11.4057 18.767 10.9501 18.3173C10.4945 17.8676 10.4945 17.1384 10.9501 16.6887L12.3749 15.2824L10.9501 13.876C10.4945 13.4263 10.4945 12.6971 10.9501 12.2474Z"
          ]}
          fill="#7C7C7C"
          className={styles.tropy_icon}
          width={25}
          height={25}
          vbWidth={26}
          vbHeight={26}
        />
      ) : (
        <SvgIcon
          paths={[
            "M4.45007 9.78011C4.86443 9.04072 5.9204 8.93695 6.53526 9.53365C6.94963 9.93578 7.04319 10.5455 6.74913 11.0254C6.48179 11.5183 6.26793 12.0242 6.10753 12.5431C5.94713 13.0879 5.42583 13.4511 4.85107 13.4511H4.8377C3.96887 13.4511 3.30054 12.6469 3.5545 11.8297C3.78174 11.1162 4.0758 10.4417 4.45007 9.78011Z",
            "M4.8377 16.0455H4.86443C5.4392 16.0455 5.9605 16.4087 6.1209 16.9795C6.2813 17.4984 6.49516 18.0043 6.76249 18.4842C7.04319 18.9771 6.93626 19.5739 6.53526 19.976C5.9204 20.5727 4.85107 20.4559 4.4367 19.7165C4.06244 19.055 3.76837 18.3675 3.5545 17.667C3.30054 16.8498 3.96887 16.0455 4.8377 16.0455Z",
            "M10.7992 24.7107C10.064 24.5032 9.35562 24.2178 8.67392 23.8546C7.91202 23.4524 7.79172 22.4277 8.39322 21.8439L8.43332 21.805C8.83432 21.4029 9.47592 21.3121 9.98385 21.5845C10.4784 21.8439 10.9863 22.0515 11.521 22.2071C12.0824 22.3628 12.4567 22.8687 12.4567 23.4265V23.4654C12.4567 24.2956 11.6279 24.9442 10.7992 24.7107Z",
            "M15.1434 3.60552V4.46166C20.4232 5.09728 24.5 9.45582 24.5 14.7483C24.5 19.4701 21.2519 23.4524 16.7875 24.6977C15.9587 24.9312 15.1434 24.2956 15.1434 23.4524V23.4265C15.1434 22.8687 15.5043 22.3628 16.0523 22.2071C19.394 21.2602 21.8267 18.2896 21.8267 14.7483C21.8267 10.8957 18.9395 7.70462 15.1434 7.08197V9.06666C15.1434 10.2212 13.7131 10.8049 12.871 10.0006L10.0239 7.30249C9.48928 6.79659 9.48928 5.96639 10.0239 5.44752L12.8577 2.68452C13.6998 1.86729 15.1434 2.45103 15.1434 3.60552Z"

          ]}
          fill="#7C7C7C"
          onClick={onClick}
          className={styles.tropy_icon}
          width={25}
          height={25}
          vbWidth={26}
          vbHeight={26}
        />
      )}
    </div>
  );
});

export default GlobeControls;
