import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const CheckoutSessions = () => (
  <Fragment>
    <Typography color="inherit">Checkout sessions</Typography>
    The total number of times customers have opened the Checkout page since midnight, tracking customer engagement in the checkout process.
  </Fragment>
);

export default CheckoutSessions;
