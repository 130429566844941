import styles from "../../css/loader.module.css";
function Loader({ isLoading, message = "loading..." }) {
  return isLoading ? (
    <div className={styles.loader_container}>
      <div className={styles.spinner}>
        <div
          className={`${styles["spinner-circle"]} ${styles["spinner-circle-outer"]}`}
        ></div>
        <div
          className={`${styles["spinner-circle-off"]} ${styles["spinner-circle-inner"]}`}
        ></div>
        <div
          className={`${styles["spinner-circle"]} ${styles["spinner-circle-single-1"]}`}
        ></div>
        <div
          className={`${styles["spinner-circle"]} ${styles["spinner-circle-single-2"]}`}
        ></div>
      </div>
      <div className={styles.text}>{message}</div>
    </div>
  ) : null;
}

export default Loader;
