import { useEffect, useState, Fragment } from "react";
import styles from "../../css/liveview.module.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HtmlTooltip from "./HtmlTooltip";
import SvgIcon from "./SvgIcon";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip placement="right" {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     padding: theme.spacing(2),
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }));
function DataBox({ title, value, tooltipComponent, iconPaths }) {
  //console.log("DataBox", icon);
  const [xvalue, setValue] = useState();
  const [xiconPaths, setIcon] = useState([]);
  useEffect(() => {
    setValue(value);
    setIcon(iconPaths);
  }, [title, value, iconPaths]);
  return (
    <div className={styles.lv_data_box}>
      <HtmlTooltip title={tooltipComponent} disableInteractive>
        <span className={styles.box_tooltip}>
          <SvgIcon
            paths={[
              "M8.99967 13.1667C8.54134 13.1667 8.16634 12.7917 8.16634 12.3333L8.16634 8.16666C8.16634 7.70833 8.54134 7.33333 8.99967 7.33333C9.45801 7.33333 9.83301 7.70833 9.83301 8.16666L9.83301 12.3333C9.83301 12.7917 9.45801 13.1667 8.99967 13.1667ZM9.00801 17.3333C13.608 17.3333 17.333 13.6 17.333 9C17.333 4.39999 13.608 0.666663 9.00801 0.666663C4.39967 0.666663 0.666339 4.4 0.666339 9C0.66634 13.6 4.39967 17.3333 9.00801 17.3333ZM8.99967 2.33333C12.683 2.33333 15.6663 5.31666 15.6663 9C15.6663 12.6833 12.683 15.6667 8.99967 15.6667C5.31634 15.6667 2.33301 12.6833 2.33301 9C2.33301 5.31666 5.31634 2.33333 8.99967 2.33333ZM8.16634 5.66666C8.16634 5.20643 8.53944 4.83333 8.99967 4.83333C9.45991 4.83333 9.83301 5.20643 9.83301 5.66666C9.83301 6.1269 9.45991 6.5 8.99967 6.5C8.53944 6.5 8.16634 6.1269 8.16634 5.66666Z"              
            ]}
            fill="#7C7C7C"
            width={18}
            height={18}
            vbWidth={20}
            vbHeight={20}
          />
        </span>
      </HtmlTooltip>
      <div className={styles.lv_data_box_inner_container}>
        <div className={styles.dbic_title}>
          <SvgIcon
            paths={xiconPaths}
            width={24}
            height={24}
            vbWidth={24}
            vbHeight={24}
          />
          {title}{" "}
        </div>
        <div className={styles.dbic_value}>{xvalue}</div>
      </div>
    </div>
  );
}

export default DataBox;
