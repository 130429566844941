import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const Transactions = () => (
  <Fragment>
    <Typography color="inherit">Transactions</Typography>
    The number of transactions customers complete since midnight, in your time zone.
  </Fragment>
);

export default Transactions;
