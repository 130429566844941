import { useEffect, useState } from "react";
import styles from "../../css/liveview.module.css";
import TransactionsGraph from "./transactionsGraph";
import SvgIcon from "./SvgIcon";
import LineGraphLegend from "./lineGraphLegend";
function LineGraph({ data, title, format, iconPath }) {
  const [graphData, setGraphData] = useState({
    hours: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    amounts: {},
  });
  useEffect(() => {
    if (data) {
      let x = data;

      const graphData = {
        hours: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23,
        ],
        amounts: data,
      };
 
      setGraphData(graphData);
   
    }
  }, [data]);

  return (
    <div className={styles.db_linechart_box}>
      <div className={styles.db_linechart_inner_container}>
        <div className={styles.line_chart_title_row}>
          <div className={styles.line_chart_title_left}>
          <div className={styles.line_chart_title}>
           
           <SvgIcon
               paths={iconPath}
               width={24}
               height={24}
               vbWidth={24}
               vbHeight={24}
             />
             <span>  {title}</span>
           </div>

          </div>
          <div className={styles.line_chart_title_right}>
            <LineGraphLegend graphData={graphData} />
          </div>
        </div>

        <div
          className={styles.db_linechart}
          style={{ width: "100%", minHeight: "300px" }}
        >
          <TransactionsGraph data={graphData} format={format} />
        </div>
      </div>
    </div>
  );
}

export default LineGraph;
