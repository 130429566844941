const SvgIcon = ({
  paths,
  width,
  fill,
  height,
  vbWidth,
  vbHeight,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      fill={fill}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
    >
      {paths.map((path, index) => {
        return <path key={index} d={path} />;
      })}
    </svg>
  );
};

export default SvgIcon;
