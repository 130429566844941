import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

const Disclaimer = () => (
  <Fragment>
    <Typography color="inherit">Important Note</Typography>
    The display is based on Global-e on-line data. Since the orders are not yet fully processed, the numbers are slightly different than in the merchant Analytics platforms.” 
  </Fragment>
);

export default Disclaimer;
