//create a function component that get as a prop merchantData
import styles from '../../css/liveview.module.css';
import React, { useState } from 'react';
function DemoMerchantData({merchantData,isLoading}) {

    const [showTools, setShowTools] = useState(false);
 
    const encryptData = () => {
        //get the selected encryption type
        const encryptType = document.querySelector('input[name="encrypt"]:checked').value;
       //get the merchant id and country code
        const merchantId = document.getElementById('merchant_id').value;
        const countryCode = document.getElementById('country_code').value;
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
 
        //call api/test_encrypt with the merchant id and country code and the encryption type
        fetch('/api/test_encrypt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ merchantId, countryCode, encryptType, username, password }),
        })
            .then((response) => response.json())
            .then((data) => {
                //set the encrypted data in the textarea
                if (data.error) {
                    document.getElementById('enc_data').value = data.error;
                    return;
                }
                document.getElementById('enc_data').value = data.encryptedData;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    
      }

      const decryptData = () => {
        //get the selected encryption type
        const encryptType = document.querySelector('input[name="encrypt"]:checked').value;
        //get the token
        const token = document.getElementById('token').value;
 
        //call api/test_decrypt with the token and the encryption type
        fetch('/api/test_decrypt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ encryptedData: token, encryptType }),
        })
            .then((response) => response.json())
            .then((data) => {
                //build a simple html from data.decryptedData merchants array
                let html = '<table><tbody>';
                for (let i = 0; i < data.decryptedData.merchants.length; i++) {
                    const merchant = data.decryptedData.merchants[i];
                    html += `<tr><td>Merchant ID: <b>${merchant.merchantId}</b></td></tr><tr><td>Country: <b>${merchant.countryCode}</b></td></tr>`;
                }
                html += '</tbody></table>';
                //set the decrypted data in the textarea

                document.getElementById('dec_data').innerHTML = html;
            })
            .catch((error) => {
                console.error('Error:', error);                
            });
        }
    return (
        <div className='tools-container' data-show={!isLoading}>
            <div>
                <h2>Debug utils</h2>
            </div>
        <div id="merchantsList">
        <table className={styles.merchants_table} style={{backgroundColor:"#fff"}}><tbody>    
            <tr key={merchantData?.merchantId} className={styles.merchant}>
            <td>{merchantData?.merchantName} ({merchantData?.merchantId})</td>
            <td>Country: {merchantData?.country}</td>
            <td>Timezone:<br/> <b>now</b>:{merchantData?.timezone?.now} <span style={{marginRight : "20px"}}></span> <br/> <b>midnight</b>: {merchantData?.timezone?.midnight}</td>
            <td>Currency Code: {merchantData?.currencyInfo?.code}<br/>Currency Rate:  {merchantData?.currencyInfo?.rate} </td>
            </tr>
    
      </tbody></table>
    </div>
    <div style={{display:"flex"}}>
        <button onClick={() => setShowTools(!showTools)} className={styles.decenc_button}>Show Encryption / Decryption Tools</button>
    </div>
    <div data-show={showTools}  className='tools-inner-container'>
      
            <div><h2>Test Encryption / Decryption</h2></div>
            <div className='tools-radio-container'>
            <input type="radio" id="encrypt_aes" name="encrypt" value="AES" />
            <label htmlFor="encrypt_aes">AES</label>
            <input type="radio" id="encrypt_jwt" name="encrypt" defaultChecked value="JWT" />
            <label htmlFor="encrypt_jwt">JWT</label>
          </div>
            <div className='enc-container'>
            <div className={styles.cont_encrypt} style={{backgroundColor:"#fff"}}>
            <table >
            <tbody>
                <tr>
                    <td colSpan={2} style={{fontSize:"15px", paddingBottom:"10px"}}>Enter Merchant Data</td>
                </tr>
                <tr>
                    <td>Merchant ID</td>
                    <td><input type="text" id="merchant_id" /></td>
                </tr>
                <tr>
                    <td>Country Code</td>
                    <td><input type="text" id="country_code" /></td>
                </tr>
                <tr>
                    <td>Username</td>
                    <td><input type="text" id="username" /></td>
                </tr>             
                <tr>
                    <td>Password</td>
                    <td><input type="text" id="password" /></td>
                </tr>                       
                <tr>
                    <td colSpan={2}>
                        <textarea id="enc_data" style={{width:"300px", height:"100px", marginTop:"20px"}}></textarea>
                    </td>
                </tr>
            </tbody>
            </table>
            <button onClick={encryptData} className={styles.decenc_button} id="encrypt_button">Encrypt</button>
            </div>

            <div className={styles.cont_decrypt} style={{backgroundColor:"#fff"}}>
            <table>
            <tbody>
                <tr>
                    <td colSpan={2}>Enter Token</td>
                </tr>
                <tr>
                    <td><input type="text" style={{width:"300px"}} id="token" /></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div id="dec_data" style={{width:"300px", height:"100px", marginTop:"20px"}}></div>
                    </td>
                </tr>      
            </tbody>          
            </table>
            <button onClick={decryptData} className={styles.decenc_button} id="decrypt_button">Decrypt</button>
            </div>
            </div>
        </div>
    </div>
    );

}

export default DemoMerchantData;