//create a container component that will hold the other components
import React, { useEffect, useState } from "react";
//import liveview.css
import styles from "../css/liveview.module.css";
import { state, LiveViewHelper } from "../helpers/LiveViewHelper";
import DemoMerchantData from "./Demo/DemoMerchantData";
import { observer } from "mobx-react-lite";
import Loader from "./common/loader";
import LiveViewDashboard from "./LiveViewDashboard";
import GeneralError from "./GeneralError";
import Header from "./Header";
const LiveView = observer(() => {
  //set message state
  const [message, setMessage] = useState("Waiting for data...");
  const [merchantData, setMerchantData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    state.init();
    //console.log("init");
    //LiveViewHelper.doPolling = false;
    // Cleanup listener on component unmount
    return () => state.releaseListener();
  }, []);

  useEffect(() => {
    LiveViewHelper.doPolling = state.autoRefresh;
    //alert(state.autoRefresh);
  }, [state.autoRefresh]);

  useEffect(() => {
    if (state.merchantData) {
      setMerchantData(state.merchantData);
      setIsLoading(false);
    }
  }, [state.merchantData]);

  useEffect(() => {
    if (state.isError) {
      setIsLoading(false);
      setMessage("Error fetching data");
    }
  }, [state.isError]);

  return (
    <div className={styles.liveViewContainer} id="liveViewContainer">
      <Header isLoading={isLoading || state.isError} />
      <Loader isLoading={isLoading} />
      <LiveViewDashboard
        isLoading={isLoading || state.isError}
      />
      <DemoMerchantData
        merchantData={merchantData}
        isLoading={state.isError || !state.debug || isLoading}
      />
      <GeneralError isError={state.isError} error={state.errorMessage} />
    </div>
  );
});

export default LiveView;
