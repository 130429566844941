import styles from "../../css/liveview.module.css";
function BarGraphRow({ percentage, label, value }) {
  return (
    <div style={{ width: "100%" }} className={styles.bar_chart_row_container}>
      <div className={styles.bar_chart_row_label}>
        <span>{label}</span>
      </div>
      <div className={styles.bar_chart_row_value_container}>
        <div
          style={{ width: percentage }}
          className={styles.bar_chart_row}
        ></div>
        <div className={styles.bar_value}>{value}</div>
      </div>
    </div>
  );
}

export default BarGraphRow;
